<template>
    <div class="max_div" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">
        <div class="vip">
            <div class="text2">
                <div class="mian2">
                    <span class="text3">用户数量</span>
                    <span class="text3">{{ num }}家</span>
                </div>
                <!-- <div class="mian2">
                <span class="text3">用户用户</span>
                <span class="text3">{{ num1 }}</span>
            </div> -->
                <div class="mian2">
                    <span class="text3">孩子数</span>
                    <span class="text3">{{ num1 }}</span>
                </div>
                <div class="mian2">
                    <span class="text3">家长数</span>
                    <span class="text3">{{ num3 }}</span>
                </div>
                <div class="mian2">
                    <span class="text3">档案数</span>
                    <span class="text3">{{ num2 }}</span>
                </div>
                <div class="mian2" v-for="(every, index) in report" :key="index">
                    <span class="text3">{{ every.mtype }}</span>
                    <span class="text3">{{ every.num }}</span>
                </div>

            </div>
            <div class="text2">
                <!-- <div class="mian2">
                    <span class="text3">家长数</span>
                    <span class="text3">{{ num3 }}</span>
                </div> -->

            </div>

            <div class="teb_header">
                <div class="hang">
                    <div style=" display: flex;">
                        <el-input v-model="cname" placeholder="用户名称" class="ss" :size="tabsize"></el-input>
                        <el-button icon="el-icon-search" type="success" :size="tabsize"
                            @click="postlist()">搜索</el-button>
                        <el-button icon="el-icon-refresh-left" type="primary" :size="tabsize"
                            @click="resetting()">重置</el-button>
                    </div>
                    <div style=" display: flex;">
                        <el-button icon="el-icon-plus" type="primary" :size="tabsize"
                            @click="add = show = true">添加</el-button>
                    </div>
                </div>
            </div>

            <div class="tbl">
                <template>
                    <el-table :data="tableData" border height="63.5vh" style="width: 100%">
                        <el-table-column prop="cname" label="用户名称" width="180" align="center">
                        </el-table-column>
                        <el-table-column prop="gname" label="管理员" align="center">
                        </el-table-column>
                        <el-table-column prop="phone" label="手机号" align="center">
                        </el-table-column>
                        <el-table-column prop="mtype" label="用户性质" align="center">
                        </el-table-column>
                        <!-- <el-table-column prop="num" label="用户数" width="120" align="center">
                    </el-table-column> -->
                        <el-table-column v-if="user.username !== '第二师铁门关市视力档案管理系统'" prop="num" label="家长" align="center">
                        </el-table-column>
                        <el-table-column v-else prop="num" label="家长数" align="center">
                        </el-table-column>
                        <el-table-column v-if="user.username !== '第二师铁门关市视力档案管理系统'" prop="num1" label="孩子" align="center">
                        </el-table-column>
                        <el-table-column v-else prop="num1" label="学生数" align="center">
                        </el-table-column>
                        <el-table-column prop="num" label="档案数" align="center">
                        </el-table-column>
                        <el-table-column label="最近登录" prop="logtime" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.logtime | logtime }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.status | qdsta }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="num" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="primary" :size="tabsize" @click="look(scope.row)">查看</el-button>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="handleClick()" type="text" size="small">查看详情</el-button>
                        </template>
                    </el-table-column> -->
                    </el-table>
                </template>
            </div>

            <el-dialog title="添加用户" :visible.sync="show" width=35% center>
                <el-form label-width="80px" :model="ruleForm" label-position="right" :rules='rules' ref="ruleForm">
                    <div class="from">
                        <div class="from2">
                            <div>
                                <el-form-item label="用户名称" prop="cname">
                                    <el-input v-model="ruleForm.cname"></el-input>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="用户简称" prop="jname">
                                    <el-input v-model="ruleForm.jname"></el-input>
                                </el-form-item>
                            </div>
                        </div>

                        <div class=from2>
                            <el-form-item label="城市:" prop="address1">
                                <div style=";display:flex;justify-content: space-between;">
                                    <el-cascader v-model='ruleForm.address1' :options="regionData" placeholder="选择城市"
                                        :props="{ expandTrigger: 'hover' }" @change="handleChange"
                                        clearable></el-cascader>
                                </div>
                            </el-form-item>
                        </div>
                        <div class="from2">
                            <el-form-item label="客服微信" prop="wxkf">
                                <el-input v-model="ruleForm.wxkf"></el-input>
                            </el-form-item>
                            <el-form-item label="服务商代码" label-width="90px" prop="code1">
                                <el-input v-model="ruleForm.code1"></el-input>
                            </el-form-item>
                        </div>
                        <div class="from2">
                            <el-form-item label="管理员" prop="gname">
                                <el-input v-model="ruleForm.gname"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="ruleForm.phone"></el-input>
                            </el-form-item>
                        </div>
                        <div class="from2">
                            <el-form-item label="用户性质" prop="mtype">
                                <el-select v-model="ruleForm.mtype" placeholder="请选择" clearable>
                                    <el-option v-for="item in storeNature" :key="item.v" :label="item.v"
                                        :value="item.k">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="用户级别" prop="mlvl">
                                <el-select v-model="ruleForm.mlvl" placeholder="请选择" clearable>
                                    <el-option v-for="item in storeLevel" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="from3">
                            <el-form-item label="登录账号" prop="loginname">
                                <el-input v-model="ruleForm.loginname"></el-input>
                            </el-form-item>
                            <el-form-item label="登录密码" prop="pwd">
                                <el-input v-model="ruleForm.pwd"></el-input>
                            </el-form-item>

                            <el-form-item label="输入授权码" label-width="90px" prop="sqcode">
                                <el-input v-model="ruleForm.sqcode"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="用户简介" prop="content">
                                <el-input type="textarea" v-model="ruleForm.content" maxlength="1000" :rows="5">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="详细地址" prop="address">
                                <el-input v-model="ruleForm.address"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="公众号二维码" prop="qrcode" label-width="100px">
                                <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
                                    :on-success="handleAvatarSuccess" :on-preview="handlePreview"
                                    :on-remove="handleRemove" :before-upload="beforeAvatarUpload"
                                    :http-request="uploadFile" :file-list="fileList" :limit="1" list-type="picture">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1MB</div>
                                </el-upload>
                            </el-form-item>
                        </div>
                    </div>
                    <p class="btns">
                        <el-button :size="tabsize" @click="show = false">取消</el-button>
                        <el-button :size="tabsize" type="primary" @click="onSubmit('ruleForm', ruleForm)">提交</el-button>
                    </p>
                </el-form>
            </el-dialog>

            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="tableData.length">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import dayjs from 'dayjs';

import { uploadOSS } from '../../../requset/apis/alioss'
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'

export default {
    computed: {
        tabsize() {
            this.$setTableHeight();
            return this.$store.state.tabsize;
        },
    },

    filters: {
        capitalize: function (value) {
            switch (value) {
                case "1":
                    value = "销售模式";
                    break;
                case "2":
                    value = "合作模式";
                    break;
                case "3":
                    break;
            }
            return value;
        }
    },
    data() {
        return {
            user:{},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            storeLevel: [
                {
                    value: 1,
                    label: 'A'
                }, {
                    value: 2,
                    label: 'B'
                }, {
                    value: 3,
                    label: 'C'
                }, {
                    value: 4,
                    label: 'D'
                }, {
                    value: 5,
                    label: 'E'
                },
            ],
            ruleForm: {
                cname: '',//用户名称
                img: '',//照片url
                jname: '',
                wxkf: '',
                add1: '',
                add2: '',
                add3: '',
                address: '',
                gname: '',
                phone: '',
                mtype: '',
                mlvl: '',
                content: '',
                loginname: '',
                pwd: '',
                qrcode: '',
                code1: '',
                sqcode: '',
                add: "",
            },
            rules: {
                cname: [{
                    required: true,
                    message: '请输入用户名称',
                    trigger: 'blur'
                }],
                jname: [{
                    required: true,
                    message: '请输入用户简称',
                    trigger: 'blur'
                }],
                wxkf: [{
                    required: true,
                    message: '请输入客服微信',
                    trigger: 'blur'
                }],
                gname: [{
                    required: true,
                    message: '请输入管理员',
                    trigger: 'blur'
                }],
                phone: [{
                    required: true,
                    pattern: /^1[3456789]\d{9}$/,
                    message: '请输入正确的联系电话',
                    trigger: 'blur'
                }],
                mtype: [{
                    required: true,
                    message: '请选择性质',
                    trigger: 'change'
                }],
                mlvl: [{
                    required: true,
                    message: '请选择用户级别',
                    trigger: 'change'
                }],
                loginname: [{
                    required: true,
                    message: '请输入登录账号',
                    trigger: 'blur'
                }],
                pwd: [{
                    required: true,
                    message: '请输入登录密码',
                    trigger: 'blur'
                }],
                address1: [{
                    required: true,
                    message: '请选择城市',
                    trigger: 'change'
                }],
                address: [{
                    required: true,
                    message: '请输入具体位置',
                    trigger: 'blur'
                }],
                code1: [{
                    required: true,
                    // min: 6, max: 6,
                    message: '请输入服务商码（6位）',
                    pattern: /^[a-z0-9]{6}$/,
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '请输入简介',
                    trigger: 'blur'
                }],
                qrcode: [{
                    required: true,
                    message: '请上传图片',
                    trigger: 'change'
                }],
                sqcode: [{
                    required: true,
                    message: '请输入授权码',
                    trigger: 'blur'
                }]
            },//表单验证
            phone: '',//手机号输入框
            formShow: false,//用于控制是否显示表单 true显示
            id: "",
            sort: "id",
            order: "desc",
            currentPage: 1, //默认显示第一页
            pageSize: 10, //默认每页显示10条
            totalNum: "", //总页数
            time: [],
            ctime: "",
            cname: "",
            tableData: [],
            num: 0, //用户数量
            num1: 0, //用户用户
            num2: 0, //档案数
            num3: 0, //家长数

            mtype: "",
            value: "",
            show: false,
            loading: false,//加载
            fileList: [],//相片列表
            regionData: [],
            rowData: {},
            storeNature: [],
            report: [{ mtype: '', num: 0 }],
            dictData: []
        };
    },
    filters: {
        logtime: function (val) {
            return val ? dayjs(val).format("YYYY-MM-DD") : ''
        }
    },
    methods: {
        handleAvatarSuccess() {
            delete this.rules.qrcode
            this.$refs['ruleForm'].clearValidate('qrcode')

        },
        handleChange(value) {
            this.ruleForm.add = value//表单验证
            this.ruleForm.add1 = value[0]
            this.ruleForm.add2 = value[1]
            this.ruleForm.add3 = value[2]
            // delete this.rules.add
            // this.$refs['ruleForm'].clearValidate('add')
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 1MB!');
            }
            return isLt2M;
        },

        uploadFile(item) {
            // console.log("上传图片", item.file);
            // this.$refs['ruleForm'].clearValidate('qrcode')
            uploadOSS(item.file).then((res) => {
                // console.log("地址", res);
                this.ruleForm.img = res.url
                this.$refs['ruleForm'].clearValidate('qrcode')
            });
        },

        handlelook(index, row) {
            console.info(row.id);
            console.info(row.birthdata);
            console.info("birthdata");
            this.$router.push({
                path: "./share",
                query: { kiid: row.id, birthdata: row.birthdata },
            });
        },
        parents() {
            this.$api.vipuser.parents({
            })
                .then((res) => {
                    this.options1 = res.obj;
                    // console.log(123);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        postlist() {
            this.loading = true;
            this.$api.channel.getlist({
                "jPage.pageNum": this.currentPage,
                "jPage.pageSize": this.pageSize,
                "jPage.sort": this.sort,
                "jPage.order": this.order,
                cname: this.cname
            })
                .then((res) => {
                    this.tableData = res.obj.list; //获取数据列表
                    this.totalNum = res.obj.totalPage; // 总页数
                    this.tableData.length = res.obj.totalRow; //总条数
                    this.currentPage = res.obj.pageNumber; //当前页码数;
                    this.loading = false

                    // for (var i = 0; i < this.tableData.length; i++) {
                    //     if (this.tableData[i].status == 10) {
                    //         this.tableData[i].status = "正常"
                    //     } else if (this.tableData[i].status == 20) {
                    //         this.tableData[i].status = "关闭"
                    //     } else {
                    //         this.tableData[i].status = "删除"
                    //     }

                    // }
                    this.$api.vipuser.getinfo({
                        dtype: 'qd_type'
                    }).then((res) => {
                        this.dictData = res.obj
                        console.log(this.dictData);
                        for (var j = 0; j < this.tableData.length; j++) {
                            for (var i = 0; i < this.dictData.length; i++) {
                                // this.mtype = this.tableData[j].mtype
                                if (this.tableData[j].mtype == this.dictData[i].lvl) {
                                    this.mtype = this.dictData[i].v
                                    this.tableData[j].mtype = this.mtype
                                }
                            }
                        }
                    }).catch((error) => {

                    });

                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 分页大小
        handleSizeChange(val) {
            this.loading = true;
            this.pageSize = val;
            this.currentPage = 1; // 选择分页大小后跳转第一页
            this.postlist();
            // console.log("分页大小" + val);
        },
        // 第几页
        handleCurrentChange(val) {
            this.loading = true;
            this.currentPage = val;
            this.postlist();
        },
        resetting() {//重置按钮
            this.cname = null
            this.postlist();
        },

        add() {//添加用户按钮

        },
        mod() {
            this.$api.channel.getnum({}).then((res) => {
                this.num = res.obj.num;
                this.num1 = res.obj.num1;
                this.num2 = res.obj.num2;
                this.num3 = res.obj.num3;
                // console.log(res);
            })

        },
        look(data) {
            // console.log(data);
            this.$router.push({ path: '/info', query: { id: data.id, status: data.status } });
        },
        onSubmit(formName) {//from提交
            this.$refs[formName].validate((valid) => {
                delete this.rules.qrcode
                this.$refs[formName].validate((valid1) => {
                    if (valid1) {
                        valid = valid1
                    }
                    this.rules['qrcode'] = [{
                        required: true,
                        message: '请上传图片',
                        trigger: 'change'
                    }]
                })
                if (valid) {
                    this.$api.channel.save({
                        cname: this.ruleForm.cname,//用户名称
                        jname: this.ruleForm.jname,
                        wxkf: this.ruleForm.wxkf,
                        add1: this.ruleForm.add1,
                        add2: this.ruleForm.add2,
                        add3: this.ruleForm.add3,
                        address: this.ruleForm.address,
                        gname: this.ruleForm.gname,
                        phone: this.ruleForm.phone,
                        mtype: this.ruleForm.mtype,
                        mlvl: this.ruleForm.mlvl,
                        content: this.ruleForm.content,
                        loginname: this.ruleForm.loginname,
                        pwd: this.ruleForm.pwd,
                        qrcode: this.ruleForm.img,
                        code1: this.ruleForm.code1,
                        sqcode: this.ruleForm.sqcode,
                    }).then((res) => {
                        // console.log(res);
                        if (res.status == 1) {
                            this.$notify({
                                title: '成功',
                                message: res.msg,
                                type: 'success'
                            });
                            this.show = !this.show;
                            this.postlist();
                        } else {
                            this.$notify({
                                title: '错误',
                                message: res.msg,
                                type: 'error'
                            });
                        }
                    })
                    // this.postlist();
                }
            });
        },
        getinfo() {//获取字典
            this.$api.channel.getinfo({
                dtype: 'qd_type'
            }).then((res) => {
                // console.log('字典', res);
                this.storeNature = res.obj
            })
        },
        report1() {
            this.$api.channel.report1({}).then((res) => {
                console.log("report", res.obj);
                this.report = res.obj
                console.log(this.storeNature);
                for (var i = 0; i < this.storeNature.length; i++) {
                    for (var j = 0; j < this.report.length; j++) {
                        if (this.report[j].mtype === this.storeNature[i].lvl) {
                            this.mtype = this.storeNature[i].v
                            this.report[j].mtype = this.mtype
                        }
                    }
                }

            }).catch((error) => { });
        }
    },
    created() {
        this.user = JSON.parse(sessionStorage.getItem("userInfo"))
        this.postlist();
        this.regionData = regionData;
        this.getinfo();
        this.report1();
    },
    mounted() {
        this.mod();

    }
};
</script>
<style  scoped>
.vip {
    position: relative;
    width: 98%;
    height: 98%;
    margin: 1%;
}

h3 {
    width: 100%;
}

.ss {
    margin-right: 2vw;
    width: 12.2vw !important;
}

.hang {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block {
    margin: 0 1vw;
}

/* .el-select {
    margin-right: 1vw;
} */

#hccode {
    margin-left: 1vw;
}

.hang2 {
    display: flex;
    align-items: center;
    margin-top: 10px;
}


.teb_header .el-input {
    width: auto;
    margin-left: 20px;
}

.el-table td {
    padding: 5px 0;
}

.el-pagination {
    float: right;
    margin-top: 10px;
}

.el-dialog__wrapper/deep/.el-dialog__header {
    text-align: center;
}

.tbl {
    min-height: 65vh;
    overflow-y: auto;
}

.text2 {

    display: flex;

    justify-content: flex-start;
    /* height: 12vh; */
    flex-wrap: wrap;
}

.mian2 {
    border-block: revert;
    width: 15vh;
    height: 7vh;
    border-style: hidden solid solid hidden;
    /* box-shadow: 8px 8px 10px 4px #aaa; */
    border-width: 0px;
    border-radius: 5px;
    margin-right: 1%;
    margin-left: 0.2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 1vh;
}

.mian3 {
    border-block: revert;
    width: 30vh;
    height: 10vh;
    border-style: hidden solid solid hidden;
    box-shadow: 8px 8px 10px 4px #aaa;
    border-width: 0px;
    border-radius: 5px;
}

.text3 {
    font-size: 15px;
}

.from {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.from2 {
    display: flex;
    justify-content: space-between;
}

.from3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


/deep/.el-input--suffix .el-input__inner {
    padding-right: 14px;
}

/deep/.el-form-item__content {
    display: flex;
}

/deep/.el-form-item__label {
    width: 106px ! important
}
</style>